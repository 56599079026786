//项目中大多数时候都会把对应的请求进行封装
import service from "@/service";

//获取下载链接的参数
export function downloadAPK(params) {
  return service({
    method: "get",
    url: "/data.txt",
    params,
  });
}
