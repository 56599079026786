<template>
  <div class="wrp">
    <div class="xy-tid">球彩AI隐私政策</div>
    <div class="cont">
      <h3>一、信息收集</h3>
      <p>我们深知个人信息保护的重要性，因此，在收集您的信息时，我们将严格遵守法律法规的要求，坚持合法、正当、必要和诚信的原则。我们收集的信息主要包括以下几类：</p>
      <span>1.您主动提供的信息：</span>
      <p>1.1当您注册或使用我们的产品或服务时，您可能需要主动填写或上传昵称、手机号码、头像、图片等个人信息。</p>
      <p>1.2您通过我们的客服或参加我们举办的活动时所提交的信息也会被收集。</p>
      <p>1.3对于某些特定的服务，我们可能需要您提供特定的个人信息来实现相关功能。若您选择不提供该类信息，将不会影响您使用其他功能。</p>
      <span>2.我们在您使用服务时获取的信息：</span>
      <p>2.1日志信息：为了维护产品与服务的安全稳定运行，我们可能会收集设备信息、软件信息、服务日志信息等。</p>
      <p>2.2位置信息：当您使用与位置有关的服务时，我们可能会记录您的位置信息，以便为您提供更精准的服务。请注意，GPS位置信息是敏感个人信息，若您拒绝提供，将仅影响与地理位置相关的功能。</p>
      <p>2.3其他相关信息：根据您的授权，我们可能收集例如通讯录等加密后的信息，以帮助您更好地使用我们的服务。</p>
      <p>2.4其他用户分享的信息：其他用户发布的照片或分享的视频中可能包含您的信息，我们提醒您注意隐私安全。</p>
      <span>3.法律允许情况下获得的信息：例如，您使用微信或QQ账户登录进行第三方合作伙伴服务时，我们会获得您登录的名称等信息，以方便您进行授权管理。</span>
    </div>

    <div class="cont">
      <h3>二、信息使用</h3>
      <p style="text-indent:0px;margin-left: 3.6rem;">我们将严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途：</p>
      <span style="margin-left: 6.2rem;">1.向您提供服务：满足您的基本服务需求。</span>
      <span style="margin-left: 6.2rem;">2.满足个性化需求：如语言设定、位置设定、个性化的帮助服务等。</span>
      <span style="margin-left: 6.2rem;">3.安全保障：用于身份验证、安全防范、反诈骗监测、存档备份等。</span>
      <!-- <span style="margin-left: 6.2rem;">4.广告推荐：向您推荐可能感兴趣的广告、资讯等。</span> -->
      <span style="margin-left: 6.2rem;">4.管理软件：进行软件认证、升级等。</span>
      <span style="margin-left: 6.2rem;">5.服务调查：邀请您参与有关我们服务的调查，以改善我们的服务质量。</span>
      <span style="margin-left: 6.2rem;">我们承诺，若超出上述用途使用您的信息，我们将再次向您说明，并征得您的同意。</span>
    </div>

    <div class="cont">
      <h3>三、信息管理</h3>
      <p>您可以通过查阅、复制、更正、补充、删除等方式来管理您的信息。我们提供了便捷的隐私设置选项，允许您控制分享信息的范围。但请注意，即使您删除了某些信息，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立保存。</p>
    </div>
    <div class="cont">
      <h3>四、第三方数据处理及信息的公开披露</h3>
      <!-- <p>1、MobSDK：</p>
      <p>作用：社会化分享。</p>
      <p>相关隐私政策：<a href="www.mob.com/wiki/detailed?wiki=421&id=610"
          target="_blank">//www.mob.com/wiki/detailed?wiki=421&id=610</a></p>
      <p>收集数据：</p>
      <p>系统运行信息：确保服务在不同设备上的兼容性。</p>
      <p>网络状态信息：网络链路的选择与优化。</p>
      <p>
        iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、SIM卡识别码（ICCID）（仅在秒验产品中收集）、SIM卡序列号（仅2022年3月份之前的版本收集）、Android
        ID（仅2022年3月份之前的版本收集）、Serial Number（仅2022年3月份之前的版本收集）:用于生成脱敏的终端用户设备唯一性标识。</p>
      <p>基站信息：实现与特定区域相关的服务。</p> -->


      <p>1、ShareSDK-Android</p>
      <p>作用:社会化分享。</p>
      <p>涉及个人信息：系统运行信息（设备平台、系统版本、设备型号、设备序列号、设备厂商、设备品牌、屏幕分辨率）、网络状态信息（IP地址、MAC地址、运营商信息、WiFi信息、基站信息、SSID、BSSID）、设备标识信息（iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）（仅2022年3月份之前的版本收集）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、Advertising ID、VAID、AAID、SIM卡识别码（ICCID）（仅在秒验产品中收集）、SIM卡序列号（仅2022年3月份之前的版本收集）、Android ID（仅2022年3月份之前的版本收集）、Serial Number（仅2022年3月份之前的版本收集）。如需要采集与前述设备标识信息存在不一致情况的，以具体产品/服务项下所列明的具体采集情况为准。）、地理位置信息（粗略位置信息及/或精确位置信息）、已安装APP信息、剪切板、社交平台OpenID（第三方账号）</p>
      <p>SDK开发者：上海掌之淘信息技术有限公司</p>
      <p>SDK类型: 社交类</p>
      <p>使用目的：提供社会化登录和分享服务</p>
      <p>使用场景：实现社会化登录和分享时使用</p>
      <p>收集方式： SDK自行采集</p>
      <p>官网链接：<a href="https://www.mob.com/" target="_blank">https://www.mob.com/</a></p>
      <p>隐私政策链接：<a href="https://policy.zztfly.com/sdk/share/privacy" target="_blank">https://policy.zztfly.com/sdk/share/privacy</a></p>

      <p>2、友盟+移动统计SDK</p>
      <p>使用目的：进行APP运营统计与分析</p>
      <p>SDK类型: 统计类</p>
      <p>SDK开发者: 友盟同欣（北京）科技有限公司</p>
      <p>收集个人信息类型：设备信息（IMEI/MAC地址/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息</p>
      <p>SDK私隐政策链接：<a href="https://www.umeng.com/page/policy" target="_blank">https://www.umeng.com/page/policy</a></p>
      <p>3、优量汇 Android SDK</p>
      <p>使用目的及功能场景：广告投放、广告归因、广告反作弊和广告效果优化</p>
      <p>SDK类型：广告类</p>
      <p>SDK开发者: 深圳市腾讯计算机系统有限公司</p>
      <p>处理个人信息类型： 粗略位置信息、设备信息（如设备制造商、设备型号、设备序列号、操作系统版本等）、设备标识符（如IMEI、AndroidID、OAID、IDFA等）、传感器、应用信息（宿主应用的包名、版本号）、已安装APP信息、广告数据（如曝光、点击数据等）
      </p>
      <p>实现优量汇SDK功能所需的权限：访问粗略位置</p>
      <p>优量汇SDK个人信息保护规则链接：<a href="https://e.qq.com/dev/help_detail.html?cid=2005&pid=5983"
          target="_blank">https://e.qq.com/dev/help_detail.html?cid=2005&pid=5983</a></p>



      <p>4、腾讯云客户端性能分析-Android端</p>
      <p>SDK开发者：腾讯云计算（北京）有限责任公司</p>
      <p>SDK类型: 性能监控类</p>
      <p>使用目的及功能场景：实时监控采集移动 App 真实用户问题，从崩溃、ANR、网络错误、慢启动、慢页面、JSError、内存泄漏等十几个问题域出发，对应用性能进行系统性治理</p>
      <p>处理的个人信息类型：设备型号、系统版本等信息</p>
      <p>实现 SDK 产品功能所需的权限：网络权限、获取网络状态权限、获取wifi状态权限</p>
      <p>SDK 隐私政策链接：https://privacy.qq.com/document/preview/d53a0ded27a645d6addcf61f2b21abd0</p>
      <p>第三方 SDK 官网链接：<a href="https://cloud.tencent.com/product/qapm" target="_blank">https://cloud.tencent.com/product/qapm</a></p>


      <p>5、Crash SDK</p>
      <p>SDK为开发者支持APP设备的崩溃监控、性能监控等服务，为了提供定位崩溃原因能力，SDK会收集设备信息（UUID、设备品牌、设备型号、设备ROM版本）、网络信息（IP地址、网络类型、网络状态）、时间戳、应用信息（应用包名、应用版本号）、开发者主动上传的数据（自定义标识、日志），上述信息会用于定位当前应用的运行状态和崩溃原因</p>
      <p>SDK类型:  性能监控类</p>
      <p>SDK开发者:  广州市动景计算机科技有限公司</p>
      <p>SDK私隐政策链接:<a href="https://cloud.tencent.com/product/qapm" target="_blank"></a>https://cloud.tencent.com/product/qapm</p>
      <p></p>

      <!-- <p>我们与第三方合作时，将遵守法律规定，按照最小必要原则处理相关数据。我们要求第三方服务商严格遵守我们的数据隐私保护措施，保障数据安全。我们不会主动公开披露您的个人信息，除非经您单独同意或法律法规另有规定。</p> -->
    </div>

    <div class="cont">
      <h3>五、信息存储与安全</h3>
      <span>1.存储地点：我们在中华人民共和国境内收集和产生的个人信息将存储在境内。</span>
      <span>2.存储期限：我们仅在为实现目的所必需的最短时间内保留您的个人信息，但也会根据法律法规要求延长存储期限。</span>
      <span>3.安全保障：我们采用加密技术、匿名化处理等手段保护您的个人信息，并建立专门的管理制度确保信息安全。</span>
    </div>

    <!-- <div class="cont">
      <h3>六、广告</h3>
      <p>我们可能向您提供个性化广告服务，但您可以选择关闭。我们严格遵守相关法律法规，确保广告内容的合法性和适宜性。</p>
    </div> -->
    <div class="cont">
      <h3>六、未成年人保护</h3>
      <p>
        我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应取得家长或法定监护人的同意。对于14周岁以下的儿童，我们要求其在家长或监护人的帮助下完成注册和使用流程，并仔细阅读相关隐私政策和儿童隐私保护声明。
      </p>
    </div>
    <div class="cont">
      <h3>七、适用范围</h3>
      <p>本隐私政策适用于由深圳市慧意计算机有限责任公司提供的所有产品和服务。若存在特定服务的隐私指引/声明与本政策不一致之处，请以特定隐私指引/声明为准。</p>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      Height: 0,
    };
  },
  created() { },

  methods: {},
};
</script>
<style scoped>
.wrp {
  width: 130rem;
  margin: 10.5rem auto 0px;
  text-align: left;
  padding-bottom: 10rem;
}

.xy-tid {
  text-align: center;
  padding-top: 10rem;
  font-size: 3.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8rem;
}

.cont {
  margin-top: 3rem;
}

.cont h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.cont span {
  margin-left: 3.6rem;
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
  margin-bottom: 2rem;
}

.cont p {
  margin-left: 6.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  text-align: justify;
  text-indent: 1.6rem;
  line-height: 2.5rem;
}
</style>